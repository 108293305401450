import imaginetest from '../../img/herobg.jpg';
import construiestemodala from '../../img/construiestemodala.png';
import construiestecarusel from '../../img/construiestecarusel.png';
import heroareajiujitsu from '../../img/heroareajiujitsu.png';

export const imagesObject = {
    'imaginetest': imaginetest,
    'construiestemodala':construiestemodala,
    'construiestecarusel':construiestecarusel,
    'heroareajiujitsu': heroareajiujitsu

}