import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { imagesObject } from "./socialMediaImgHelper"
import websiteBg from "../../img/website.png"

const Head = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          keywords
        }
      }
    }
  `)


  const domain = "http://www.mintwebtuts.ro"
  const description = props.description
    ? props.description
    : data.site.siteMetadata.description
  const keywords = props.keywords
    ? props.keywords
    : data.site.siteMetadata.keywords
  const url = props.url ? `${domain}/blog/${props.url}` : domain
  const socialMediaImg = props.socialMediaImgUrl
    ? imagesObject[props.socialMediaImgUrl]
    : websiteBg

  return (
    <Helmet defer={false}>
      <title>{`${props.title} | ${data.site.siteMetadata.title}`}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={keywords} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        property="og:title"
        content={`${props.title} | ${data.site.siteMetadata.title}`}
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={socialMediaImg} />
      <meta
        http-equiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta http-equiv="Pragma" content="no-cache" />
      <meta http-equiv="Expires" content="0" />
    </Helmet>
  )
}
export default Head
